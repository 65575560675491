$(function() {
    var $target = $("#category-content");
    $target.text().length >= 40 && $target !== '' ? $(".category-page-exerpt-target").html($target.find("p:first").text()) : $(".category-page-exerpt").hide();
});


let categoryToggleMore = false;
function extendMore(e) {
    categoryToggleMore = !categoryToggleMore;
    const elItems = document.getElementsByClassName('category-filter')[0].getElementsByClassName('category-filter__item')
    if (categoryToggleMore) {
        e.innerHTML = 'Toon minder'
        for (let i = 3; i < elItems.length; i++) {
            elItems[i].classList.add('shown')
        }
    } else {
        for (let i = 3; i < elItems.length; i++) {
            e.innerHTML = 'Toon alle'
            elItems[i].classList.remove('shown')
        }
    }
}
